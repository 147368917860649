<template>
  <div class="root">
    <div class="critere_container text-center">
      <div class="feuille"><img src="../assets/feuille.svg" /></div>
      <h1 class="main_title">{{ lang.coordonnees_title }}</h1>

      <h3 class="subtitle my-4" v-html="lang.coordonnees_subtitle"></h3>

      <form class="form row step">
        <div class="col-md-8 mx-auto">
          <input
            type="text"
            class="form-control mb-2"
            v-model="nom"
            name="nom"
            :placeholder="lang.placeholder_nom"
            required
          />
          <input
            type="text"
            class="form-control mb-2"
            v-model="prenom"
            name="prenom"
            :placeholder="lang.placeholder_prenom"
            required
          />
          <input
            type="email"
            class="form-control mb-2"
            v-model="email"
            name="email"
            :placeholder="lang.placeholder_email"
            required
          />
          
          <select name="departement" v-model="departement" class="form-select" :placeholder="lang.placeholder_departement">
            <option value="" disabled>{{lang.placeholder_departement}}</option>
            <option :value="d.num_dep +'-'+d.dep_name.toLowerCase()" v-for="d in departements" :key="d.dep_name">{{d.dep_name}}</option>
          </select>

          <!--<vue-select
            class="form-select"
            :options="departements"
            v-model="departement"
            label-by="num_dep + '-' + dep_name"
            close-on-select
            searchable
            :search-placeholder="lang.rechercher"
            :placeholder="lang.placeholder_departement"
          ></vue-select>-->

          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="recontact"
              id="recontact"
            />
            <label class="form-check-label" for="recontact">
              <strong>{{ lang.contact }}</strong>
            </label>
          </div>
          <input
            type="text"
            class="form-control mb-2"
            name="tel"
            v-model="tel"
            :placeholder="lang.placeholder_tel"
            required
            v-if="recontact"
          />

          <div class="form-check rgpd-check">
            <input
              class="form-check-input"
              type="checkbox"
              required
              id="rgpd"
              name="rgpd"
              v-model="rgpd"
            />
            <label
              class="form-check-label"
              for="rgpd"
              v-html="lang.rgpd"
            ></label>
          </div>
        </div>
      </form>
    </div>

    <div class="bottom-bar">
      <div class="container">
        <div class="row">
          <div class="stepper col-12">
            <div
              class="step_marker"
              v-for="i in 6"
              :key="i"
              :class="{ current: i == cnum }"
            ></div>
          </div>
        </div>

        <div class="row justify-content-md-center">
          <div class="col-md-4 col-6">
            <router-link to="/critere5" class="btn btn-secondary d-block">{{
              lang.precedent
            }}</router-link>
          </div>
          <div class="col-md-4 col-6">
            <a
              href="#"
              @click="sendForm()"
              class="btn btn-primary d-block"
              v-bind:class="{ 'disabled btn-light': !formok }"
              >{{ lang.voir_resultats }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import VueSelect from "vue-next-select";

export default {
  name: "coordonnees",
  data() {
    return {
      cnum: 6,
      recontact: false,
      rgpd: false,
    };
  },
  methods: {
    isEmail: function () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.email).toLowerCase());
    },
    isPhone: function () {
      const re = /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/;
      return re.test(String(this.tel).toLowerCase());
    },
    sendForm: function () {
      if (this.formok) {
        this.$router.push("resultats");
      }
      //return false;
    },
  },
  computed: {
    lang() {
      let langIndex = this.$store.state.lang;
      return this.$store.state.languages[langIndex];
    },
    departements() {
      return this.$store.state.departements;
    },
    formok() {
      if (
        this.nom.length > 0 &&
        this.prenom.length > 0 &&
        this.email.length > 0 &&
        this.isEmail() &&
        this.departement != false &&
        this.rgpd != false
      ) {
        if (this.recontact && !this.isPhone(this.tel)) {
          return false;
        }
        return true;
      }
      return false;
    },
    nom: {
      set(value) {
        this.$store.commit("setNom", { nom: value });
      },
      get() {
        return this.$store.state.nom;
      },
    },
    prenom: {
      set(value) {
        this.$store.commit("setPrenom", { prenom: value });
      },
      get() {
        return this.$store.state.prenom;
      },
    },
    email: {
      set(value) {
        this.$store.commit("setEmail", { email: value });
      },
      get() {
        return this.$store.state.email;
      },
    },
    departement: {
      set(value) {
        this.$store.commit("setDepartement", { departement: value });
      },
      get() {
        if(this.$store.state.departement==false) {
          return "";
        }
        return this.$store.state.departement;
      },
    },
    tel: {
      set(value) {
        this.$store.commit("setTel", { tel: value });
      },
      get() {
        return this.$store.state.tel;
      },
    },
  },
  components: {
    //"vue-select": VueSelect,
  },
  beforeMount() {
    if (
      this.$store.state.critere1 == false ||
      this.$store.state.critere2 == false ||
      this.$store.state.critere31 == false ||
      this.$store.state.critere32 == false ||
      this.$store.state.critere4 == false ||
      this.$store.state.critere5 == false
    ) {
      this.$router.push("comment-ca-marche");
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss">
.rgpd-check {
  background: none;
  box-shadow: none;
  font-size: 0.8em;
  input {
    font-size: 0.85rem;
    align-self: flex-start;
  }
}
</style>